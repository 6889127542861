import { render, staticRenderFns } from "./main-menu.page.vue?vue&type=template&id=d5ec2968&scoped=true&"
import script from "./main-menu.page.vue?vue&type=script&lang=js&"
export * from "./main-menu.page.vue?vue&type=script&lang=js&"
import style0 from "./main-menu.page.vue?vue&type=style&index=0&id=d5ec2968&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5ec2968",
  null
  
)

export default component.exports